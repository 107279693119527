.top,
.bottom {
    align-items: center;
    display: flex;
}

.top {
    gap: 8px;
    padding-right: 20px;
    margin-bottom: 20px;
    margin-right: -20px;
    overflow-x: auto;
    user-select: none;
}

.top::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.bottom {
    background: #fff;
    bottom: 0;
    border-top: 1px solid var(--box-shadows);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-inline: -20px;
    padding-block: 16px;
    position: sticky;
}

.bottom * {
    margin: 0 !important;
}

.closed {
    display: none;
    pointer-events: none;
}

.container {
    background: #fff;
    height: 100vh;
    height: 100dvh;
    left: 0;
    overflow-y: auto;
    overscroll-behavior: contain;
    padding-inline: var(--container-padding);
    padding-top: var(--container-padding);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 50;
}

button.btn {
    --height: 40px;
    background: var(--teal);
    border-radius: 8px;
    color: #fff;
    padding: 0 12px;
}