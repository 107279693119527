.a,
.a img {
    display: block;
    height: auto;
    max-width: 100%;
    width: auto;
}

.a {
    cursor: pointer;
    user-select: none;
}

.disabled {
    pointer-events: none;
}

@media screen and (min-width: 1112px) {
    .a,
    .a img {
        width: max-content;
    }
}